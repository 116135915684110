<template>
  <main class="dashboard">

    <h3>{{ object?.text }}</h3>
    <div class="content-area">
      <div class="content-wrapper">
        <div class="content-body" id="document_body">
          <a class="add-comment" v-show="hasSelection" @click.prevent="showComment" title="Добавить комментарий"
             :style="{left: parseInt(mouse.x)+'px', top: parseInt(mouse.y)+'px'}">
            <i class="bi bi-chat-left"></i>
            <span>+</span>
          </a>


          <form @submit.prevent="addComment" class="comment" v-if="comment"
                :style="{left: parseInt(mouse.x)+'px', top: parseInt(mouse.y)+'px'}">
            <div class="user-area">
              <div :class="'user-pic'+(comment.user_data.is_staff?' staff':'')">
                <span>{{ getAcronym(comment.user_data) }}</span>
              </div>
              <div class="user-info">

                <div class="user-name">{{ comment.user_data.organization }}</div>
                <div class="user-email">{{ comment.user_data.email }}</div>

              </div>

            </div>

            <textarea id="comment_text" required name="" v-model="comment.text" placeholder="Комментарий..."></textarea>
            <div class="buttons">
              <button v-if="comment.id" class="btn btn-danger btn-sm" type="button" v-on:click="deleteComment(comment)"
                      title="Удалить"><i
                  class="bi bi-trash3"></i></button>
              <button class="btn btn-sm text-secondary" type="button" title="Отмена" @click.prevent="comment=null;">
                Отмена
              </button>
              <button class="btn btn-primary rounded-5 btn-sm" type="submit" title="Сохранить">Отправить
              </button>

            </div>
          </form>
          <template v-for="(element, index) in parsed_content">
            <div :id="'element_'+index" :class="'element' + (element.edit?' focus':'') + (element.comment?' hover':'')"
                 v-html="doHighlight(element.text, index)">
            </div>
          </template>
        </div>
        <div class="chat">
          <Chat v-if="object?.id" :object_id="object.id" :content_type="object.content_type"
                :chat_id="getChatId"></Chat>
        </div>
      </div>

    </div>


  </main>
</template>

<script>
import {DictionaryApi} from '@/api/dictionary'
import Chat from "@/components/chat/Chat.vue";
import {ChatApi} from "@/components/chat/api";

export default {
  name: 'ContentDetail',
  data() {
    return {
      name: 'contents',
      object: null,
      parsed_content: [],
      chat_id: null,
      mouse: {x: 0, y: 0},
      hasSelection: false,
      selectedRange: null,
      currentOffsets: {},
      comment: null
    }
  },
  components: {Chat},
  computed: {
    offsets() {
      let comments = this.object?.content?.comments;
      let ranges = {};
      if (comments) {
        comments.forEach(comment => {
          Object.keys(comment.position).forEach(position => {
            if (!ranges[position]) ranges[position] = {}
            ranges[position][comment.id] = comment.position[position];
          })

        })
      }
      return ranges;

    }
  },
  mounted: function () {
    const $this = this;
    DictionaryApi.get_detail(this.name, this.$route.params.id).then(response => {
      this.object = response
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(this.object.content.text.replaceAll("<br/>", "\n"), 'text/html');
      htmlDoc.querySelector("body").children.forEach((x, i) => {
        this.parsed_content.push({text: x.outerHTML, comment: this.object.content.comments.find(x => x.index === i)})
      })

    });
    document.onmouseup = this.getSelectedText;
    document.onkeyup = this.getSelectedText;

    document.addEventListener('click', e => {
      if (e.target.closest('.highlight')) {
        const baseArea = document.getElementById("document_body");
        $this.comment = $this.object.content.comments.find(x => x.id === parseInt(e.target.getAttribute('data-id')))
        const rect = e.target.getBoundingClientRect();
        $this.mouse = {
          x: rect.x - baseArea.offsetLeft + 5,
          y: rect.y - baseArea.offsetTop + baseArea.closest("section").scrollTop - 30
        };
      }
    })


  },
  methods: {
    getAcronym(user) {
      const name = user.fullname ? user.fullname : user.organization;
      return name.split(/\s/).reduce((r, w) => r += w.slice(0, 1), '');
    },
    getText(element, range, index, comment_id) {
      const child = element.childNodes;
      child.forEach(node => {
        if (node.nodeType === 3) {
          let text = node.textContent;
          const slice = text.slice(range.start - index, range.end - index)

          if (slice) {
            text = text.substring(0, range.start - index) + `<span data-id="${comment_id}" class="highlight">${slice}</span>` + text.substring(range.end - index, text.length);
            node.replaceWith(document.createRange().createContextualFragment(text));
          }
          index += node.textContent.length

        } else index = this.getText(node, range, index, comment_id)
      })
      return index
    },
    doHighlight(html, index) {
      let ranges = this.offsets[index]
      if (ranges) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(html, 'text/html');
        const element = htmlDoc.querySelector("body").firstElementChild
        Object.keys(ranges).forEach(comment_id => {
          let range = ranges[comment_id];
          let start_index = 0;
          this.getText(element, range, start_index, comment_id);
        })
        return element.outerHTML
      }
      return html
    },
    getSelectedText() {
      if (typeof window.getSelection != "undefined") {
        const selection = window.getSelection();
        const text = selection.toString();
        const baseArea = document.getElementById("document_body");
        if (selection.rangeCount && text && selection.getRangeAt(0).startContainer.parentNode.closest("div").classList.contains('element')) {
          let oRange = selection.getRangeAt(0);
          let range = oRange.cloneRange();
          if (range.getClientRects) {
            range.collapse(false);
            let rects = range.getClientRects();
            if (rects.length > 0) {
              this.comment = null;
              this.currentOffsets = {text: text};
              let rect = rects[0];

              let containers = [];
              if (oRange.startContainer === oRange.endContainer) {
                containers.push(oRange.startContainer.parentNode.closest("div"));
              } else {
                let children = baseArea.children;
                containers = Array.from(children || []).filter(node => selection.containsNode(node.firstElementChild, true));
              }
              let globalStart = 0;
              const getStart = (node, counter) => {
                if (node.nodeType === 3) {
                  if (!selection.containsNode(node)) {
                    if (!globalStart) counter += node.nodeValue.length;
                  } else globalStart = counter
                } else node.childNodes.forEach(x => {
                  counter = getStart(x, counter)
                })
                return counter
              };


              containers.forEach(container => {
                const _id = container.id.split("_")[1];

                if (!this.currentOffsets[_id]) this.currentOffsets[_id] = {}
                if (container.id === oRange.startContainer.parentNode.closest("div").id) {
                  let start = oRange.startOffset;
                  globalStart = 0
                  start += getStart(container, 0);
                  this.currentOffsets[_id][0] = {
                    start: start,
                    end: container.id === oRange.endContainer.parentNode.closest("div").id ? start + oRange.toString().length : container.innerText.length
                  }
                } else if (container.id === oRange.endContainer.parentNode.closest("div").id) {
                  this.currentOffsets[_id][0] = {
                    start: container.id === oRange.startContainer.parentNode.closest("div").id ? oRange.startOffset : 0,
                    end: oRange.endOffset
                  }
                } else {
                  this.currentOffsets[_id][0] = {start: 0, end: container.innerText.length}
                }
              })

              this.mouse = {
                x: rect.x - baseArea.offsetLeft + 5,
                y: rect.y - baseArea.offsetTop + baseArea.closest("section").scrollTop - 30
              };
              this.hasSelection = true;
            }
          }
        } else {
          this.hasSelection = false;
        }


      }
    },
    getChatId(_id) {
      this.chat_id = _id
    },
    addComment() {
      let offsets = this.comment.offsets;
      let data = {position: {}, text: this.comment.text, content_file: this.object.content.id};
      let comment_data = {
        text: data.text,
        blockquote: this.currentOffsets.text
      }
      delete this.comment.offsets["text"];
      Object.keys(offsets).forEach(function (key, index) {
        offsets[key] = offsets[key][Object.keys(offsets[key])[0]];
      });
      data.position = offsets;

      if (!data["id"]) ChatApi.add_message(this.chat_id, comment_data)
      DictionaryApi.add_comment(this.$route.params.id, data).then(resp => this.object.content.comments.push(resp))
      this.comment = null;
      this.currentOffsets = {};
    },
    showComment() {
      let offsets = this.currentOffsets;

      this.comment = {
        offsets: offsets,
        user_data: this.$store.getters.user,
      };

      setTimeout(() => {
        const textarea = document.getElementById("comment_text");
        textarea.style.height = textarea.scrollHeight + "px";
        textarea.style.overflowY = "hidden";

        textarea.addEventListener("input", function () {
          this.style.height = "auto";
          this.style.height = this.scrollHeight + "px";
          if (this.scrollHeight > 60) this.style.borderRadius = "14px";
        });
      }, 1000)


    },
    deleteComment(element) {
      if (element.comment.id) DictionaryApi.delete_comment(this.$route.params.id, {id: element.comment.id}).then(resp => {
        element.comment = null;
      }).catch((err) => {
        this.$notify({
          type: 'error',
          text: "Действие запрещено"
        })
      });
      else element.comment = null;
    }
  },

}

</script>
<style lang="scss">
h3 {
  border-bottom: 1px solid rgb(18, 18, 18);
  padding-bottom: 20px;
  margin-bottom: 15px !important;
}

.highlight {
  background: rgba(139, 139, 139, 0.33);
}

.add-comment {
  border: 1px solid rgba(234, 234, 234, 1);
  box-shadow: 0 0 8.8px 0 rgba(0, 0, 0, 0.27);
  backdrop-filter: blur(11px);
  background: #fff;
  height: 33px;
  width: 58px;
  position: absolute;
  border-radius: 10px;
  z-index: 10;
  color: rgba(55, 128, 255, 1);
  font-size: 21px;
  cursor: pointer;

  & > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  i {
    top: 19px;
  }

}

.comment {
  border: 1px solid rgba(234, 234, 234, 1);
  box-shadow: 0 0 8.8px 0 rgba(0, 0, 0, 0.27);
  position: absolute;
  backdrop-filter: blur(11px);
  background: #fff;
  z-index: 10;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .user-area {
    display: flex;
    gap: 10px;


    .user-pic {
      width: 38px;
      height: 38px;
      border-radius: 38px;
      background: #8A919CFF;
      margin-top: auto;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      &.staff {
        background: #000;
        color: transparent;

        span {
          background: url('@/assets/images/orion_small.svg') no-repeat center center;
          background-size: contain;
          width: 25px;
          height: 25px;
        }

      }
    }


    .user-info {
      width: 80%;
      border: none;
      border-radius: 15px 15px 15px 0;
      position: relative;
      max-width: 350px;
      display: flex;
      flex-direction: column;


      .user-name {
        font-size: 14px;
        font-weight: 500;
      }

      .user-email {
        font-size: 10px;
      }
    }

  }

  textarea {
    width: 345px;
    height: 44px;
    min-width: auto;
    outline: 0;
    resize: none;
    border-radius: 70px;
    border: 1px solid rgba(154, 154, 154, 1);
    padding: 10px 24px;;


    &::placeholder {
      color: #B3B3B3;
      font-size: 14px;
      line-height: 21px;
      //padding: 6px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .buttons {
    display: flex;
    gap: 15px;
    margin-left: auto;
  }
}

.content-area {
  display: flex;
  gap: 20px;

  .content-wrapper {
    display: flex;
    gap: 50px;
  }

  .chat {
    flex: 1 1 auto;
  }

  .content-body {
    width: 60%;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    background: white;
    border-radius: 20px;
    padding: 10px;
    display: block;
    position: relative;
    font-size: 14px;
    white-space: pre-line;


    .element {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-right: 15px;

      *::selection {
        background-color: rgba(255, 190, 0, 0.58);
      }

    }

  }

  img {
    display: block;
    width: 100% !important;
  }
}
</style>