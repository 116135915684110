<template>
  <div>
    <form v-on:submit.prevent="submitReport" class="rounded border border-primary snap-report">
      <div class="row mb-3 align-items-center">
        <h5><i class="bi bi-file-earmark-arrow-down"></i>Отчёты</h5>
        <div class="d-flex">
          <select required v-model="report.report_type" id="id_report" class="form-select">
            <option v-for="(type_name, type) in report_types" :value="type" :key="type">{{ type_name.name }}</option>
          </select>


          <span class="me-2">на</span>

          <date-picker v-model="report.date"
                       locale="ru"
                       :clearable="false"
                       format="dd.MM.yyyy"
                       model-type="yyyy-MM-dd"
                       :allowed-dates="allDates.length?allDates:undefined"
                       auto-apply
                       :enable-time-picker="false"></date-picker>

          <label for="id_state">Шаблон</label>
          <select v-model="report.template" id="id_template" class="form-select">
            <option v-for="(name, val) in template_options" :value="val">{{ name }}</option>
          </select>
        </div>
      </div>

      <button class="btn btn-success" type="submit">Сформировать отчёт</button>
      <div class="info pt-1">
         <template v-if="request.id && !request.report_file ">
      Ожидание результата ...
    </template>
    <template v-if="request.report_file">
      <a :href="request.report_file"><i class="bi bi-file-arrow-down"></i> Скачать отчёт</a><br>

    </template>
    <template v-else-if="request.error">
      <span class="text-danger"><i class="bi bi-exclamation-lg"></i> Произошла ошибка</span>
    </template>
      </div>
    </form>


  </div>
</template>

<script>
import {ReportApi} from "@/api/reports";
import moment from "moment/moment";

export default {
  name: "SmallReportSnap",
  props: ['project_id', 'date_start', 'date', 'state', 'country_id', "allDates"],
  data() {
    return {
      template_options: {
        dr: 'тональность - цвет ссылок',
        dr_bg: 'тональность - фон',
      },
      report_types: {
        top_search_results_report: {
          name: "Отчёт выдачи PPTX",
          settings: {
            date: false,
            state: true,
            country: false,
            template: true
          }
        }
      },
      request: {id: null, path: '', error: false},
      timerId: null,
      report: {report_type: 'top_search_results_report'}
    }
  },
  components: {},

  watch: {
    project_id() {
      this.setReport()
    },
    state() {
      this.setReport()
    },
    country_id() {
      this.setReport()
    },
    report: {
      handler(val) {
        if (this.report_types[val.report_type] && !this.report_types[val.report_type].settings.date && val.date) {
          this.report.date_start = val.date;
        }
      },
      deep: true
    }
  },
  computed: {},
  mounted() {
    this.setReport()
  },
  methods: {
    setReport() {
      this.report = {
        report_type: 'top_search_results_report', 'state': this.state,
        date: this.date_start,
        date_start: this.date_start,
        project: this.project_id,
        source: 'client_search_result',
        additional: {countries: [this.country_id]},
        template: 'dr'
      }
    },
    submitReport() {
      let $this = this;
      ReportApi.create_report(this.report).then(response => {
        $this.request.id = response.id;
        $this.timerId = setInterval(() => {
          $this.getReport($this.request.id)
        }, 10000);
        $this.$notify({
          group: 'alert',
          type: 'success ',
          text: 'Отчёт отправлен на формирование, результат так же можно получить в разделе "Отчёты"'
        });
      }).catch(err => {
        this.report.project = this.project_id;
        let e = err.response.data;
        typeof (e) == 'object' && e.non_field_errors !== undefined && (e = e.non_field_errors.toString());
        this.$notify({
          group: 'alert',
          type: 'error ',
          text: e
        });
      });
    },
    getReport(id) {
      const $this = this;
      ReportApi.get(id).then(resp => {
        $this.request = {...$this.request, ...resp}
        if ($this.request.error || $this.request.report_file) {
          clearInterval($this.timerId)
          $this.$notify({
            group: 'alert',
            type: 'success ',
            text: `Отчёт сформирован`
          });
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.snap-report {
  padding: 5px !important;
  margin-top: -90px;
  width: 60%;
  margin-left: auto;

  .d-flex {
    gap: 10px;
    align-items: center;

    .dp__main {
      width: 150px;
      flex: 0 0 auto;
    }
  }
}
@media (width < 768px) {
  .snap-report {
  padding: 10px !important;
  margin-top: auto;
    width: 100%;

  .d-flex {
    flex-direction: column;
    gap: 10px;
    text-align: left;
    align-items: start;

  }
}
}
</style>
