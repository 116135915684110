import { HTTP } from './common'

export const LinkApi = {
  get_content (id) {
    return HTTP.get(`user/links/${id}/contents/`).then(response => {
      return response.data
    })
  },
  update_link(link, id, project_id) {
    return HTTP.post(`user/links/${id?id:link.id}/update_link/?project_id=`+project_id, link).then(response => {
      return response.data
    });
  },
  get (id, date, project_id) {
    return HTTP.get(`user/links/${id}/?project=${project_id}${date?'&date='+date:''}`).then(response => {
      return response.data
    })
  },
  list (query) {
    return HTTP.get(`user/links/?${query}`).then(response => {
      return response.data
    })
  },
  get_chart_data (id, state, key) {
    return HTTP.get(`user/links/${id}/chart_data/?state=top_${state}&key=${key}`).then(response => {
      return response.data
    })
  },
  get_chart_keyword (id) {
    return HTTP.get(`user/links/${id}/chart_keyword_link/`).then(response => {
      return response.data
    })
  },

};
