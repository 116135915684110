<template>
  <div class="chat">
    <div class="body" id="chat">
      <div class="chat-loader" v-if="load"></div>
      <template v-for="message in messages">

        <div :class="'message-area'+(message.user_data.id === $store.getters.user.id?' self':'')">
          <div :class="'user-pic'+(message.user_data.is_staff?' staff':'')">
            <span>{{ getAcronym(message.user_data) }}</span>
          </div>
          <div class="message">

            <div class="user-name">{{ message.user_data.organization }}</div>
            <div class="user-email">{{ message.user_data.email }}</div>
            <div class="files" v-if="message.files.length">
              <div class="file" v-for="file in message.files">
                <a :href="file.file" target="_blank" class="file-name">
                  <div class="ico">
                    <i :class="'bi bi-'+(fileTypes[file.mimetype.split('/', 1)[0]] || 'file-earmark-medical')"></i>
                  </div>
                  <div class="name">
                    <span>  {{ file.name }}</span>
                    <span class="size">{{ $sizeInMb(file.size) }}</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="text">
              <blockquote v-if="message.blockquote">
                {{ message.blockquote }}
              </blockquote>
              <span v-html="message.text"></span>
            </div>
            <div class="date-time">
              {{ $moment(message.date_create).format("DD.MM.YYYY HH:mm") }}
            </div>
          </div>

        </div>

      </template>
    </div>
    <div class="footer">
      <form id="chat_form" :class="(new_message.text || files.length)?'has-content':''" action=""
            @submit.prevent="addMessage()">
        <div class="file-wrapper" v-if="openFile">
          <a href="#" @click.prevent="openFile=false;" class="close"><i class="bi bi-x"></i></a>
          <label @dragenter.prevent="setActive"
                 @dragover.prevent="setActive"
                 @dragleave.prevent="setInactive"
                 @drop.prevent="onDrop" for="chat_file" :class="'file-area'+(activeFile?' active':'')">
            <span class="ico"></span>
            <span class="title">Перетащите файл сюда</span>
            <span class="sub-title" v-if="!activeFile">или нажмите кнопку</span>
            <span class="btn btn-sm btn-primary rounded-5" v-if="!activeFile">Выбрать файл</span>
            <input class="form-control"
                   multiple="multiple"
                   @change="addFile($refs.chat_file.files)"
                   ref="chat_file" type="file"
                   id="chat_file">
          </label>
        </div>
        <div class="attach-area" v-if="files?.length">
          <span
              class="title">Прикреплен{{ files.length > 1 ? "о" : "" }} {{
              files.length
            }} {{ $Declension(files.length, ["файл", "файла", "файлов"]) }}: </span>
          <div class="files">

            <div class="file" v-for="(file, _index) in files">
              <div class="file-name">
                <div class="ico">
                  <i :class="'bi bi-'+file.type"></i></div>
                <div class="name">
                  <span> {{ file.name }}</span>
                  <span class="size">{{ $sizeInMb(file.size) }}</span>
                </div>
              </div>
              <a href="" class="delete" @click.prevent="files.splice(_index, 1)">
                <i class="bi bi-trash-fill"></i>
              </a>

            </div>
          </div>
        </div>
        <textarea id="text_area" v-model="new_message.text" placeholder="Сообщение..." required></textarea>

        <div class="buttons">

          <button @click.prevent="openFile=!openFile" class="btn btn-sm text-secondary">Добавить</button>
          <div class="group">
            <button @click.prevent="new_message={}; files =[];" class="btn btn-sm text-secondary">Отмена</button>
            <button type="submit" class="btn btn-primary btn-sm rounded-5">Отправить</button>
          </div>
        </div>
        <a href="#" @click.prevent="openFile=!openFile" class="open-file"><i class="bi bi-paperclip"></i></a>
      </form>

    </div>
  </div>
</template>

<script>

import {ChatApi} from "@/components/chat/api";
import moment from "moment";

export default {
  name: 'Chat',
  props: {
    object_id: {type: Number},
    content_type: {type: Number},
    chat_id: {type: Function || null},
  },
  data() {
    return {
      chat: null,
      new_message: {},
      messages: [],
      files: [],
      chatSocket: null,
      page: 1,
      load: false,
      openFile: false,
      activeFile: false,
      inActiveTimeout: null,
      fileTypes: {
        video: "film",
        image: "card-image",
        application: "file-earmark-medical",
        text: "card-text"
      }
    }
  },
  mounted: function () {
    this.scrollBottom()
    let $this = this;
    ChatApi.get(this.object_id, this.content_type).then(r => {
      $this.chat = r
      $this.chat_id($this.chat.id)
      $this.connectChatWs()
    })
    const chat_area = document.getElementById('chat');
    chat_area.addEventListener(
        'scroll',
        function () {
          if (!chat_area.scrollTop) {
            if ($this.page) {
              $this.load = true;
              $this.getMessages(false)
            }
          }
        },
        false
    )
    const textarea = document.getElementById("text_area");
    // textarea.addEventListener("keypress", e => {
    //   if (e.key === "Enter" && !e.shiftKey) {
    //     e.preventDefault();
    //
    //     $this.addMessage();
    //   }
    // });
    textarea.style.height = textarea.scrollHeight + "px";
    textarea.style.overflowY = "hidden";

    textarea.addEventListener("input", function () {
      this.style.height = "auto";
      this.style.height = this.scrollHeight + "px";
      if (this.scrollHeight > 48) this.closest("form").style.borderRadius = "14px";
    });

  },
  computed: {},
  watch: {
    chat() {
      this.getMessages();

    }

  },
  unmounted() {
    if (this.chatSocket) this.chatSocket.close();
  },
  methods: {
    setActive() {
      this.activeFile = true;
      clearTimeout(this.inActiveTimeout)
    },
    setInactive() {
      const $this = this;
      this.inActiveTimeout = setTimeout(() => {
        $this.activeFile = false;
      }, 50)
    },

    onDrop(e) {
      this.setInactive();
      this.addFile(e.dataTransfer.files)
    },

    getAcronym(user) {
      const name = user.fullname ? user.fullname : user.organization;
      return name.split(/\s/).reduce((r, w) => r += w.slice(0, 1), '');
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    },

    addFile(files) {
      const $this = this;
      files.forEach(async file => {
        const b64file = await $this.toBase64(file);
        $this.files.push({
          name: file.name,
          file: b64file,
          size: file.size,
          type: $this.fileTypes[file.type.split("/", 1)[0]] || "file-earmark-medical"
        })
      })
      this.openFile = false;
      document.getElementById("chat_form").style.borderRadius = "14px";
    },

    getMessages(scroll = true) {
      const $this = this;
      ChatApi.list(this.chat.id, this.page).then(r => {
        let messages = r.results;
        messages.sort((a, b) => {
          return moment(a.date_create).diff(moment(b.date_create))
        })
        $this.messages = messages.concat(this.messages);
        $this.page += r.next ? 1 : (-$this.page);
        if (scroll) $this.scrollBottom()
        $this.load = false;
      })
    },
    connectChatWs() {
      const $this = this;
      const URL = `${location.protocol.includes("https") ? "wss" : "ws"}://${this.$NODE_ENV === "development" ? this.$VUE_APP_WS_URL : location.host}/ws/`
      this.chatSocket = new WebSocket(
          `${URL}chat/${this.chat.id}/${this.$store.getters.user.id}/`
      );

      this.chatSocket.onmessage = function (e) {
        let data = JSON.parse(e.data)
        $this.messages.push(data);
        $this.scrollBottom()
      };

      this.chatSocket.onclose = function (e) {
        if ($this.chat.id) {
          setTimeout(function () {
            $this.connectChatWs();
          }, 1000);
        }
      };
      this.chatSocket.onerror = function (err) {
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        $this.chatSocket.close();
      };
    },

    addMessage() {
      const fileSum = this.files.map(x => x.size)?.reduce((a, x) => a + x, 0);
      if (fileSum > 10 * 1024 * 1024) {
        this.$notify({
          type: 'error',
          text: `Максимальный размер файлов 10Мб`
        });
      } else {
        this.chatSocket.send(JSON.stringify({message: this.new_message, files: this.files}));
        this.new_message = {};
        this.files = [];
      }
    },
    scrollBottom() {
      const objDiv = document.getElementById("chat");
      setTimeout(() => objDiv.scrollTop = objDiv.scrollHeight, 300)

    }
  },

}

</script>
<style lang="scss" scoped>
$blue: #DDF2FF;
$gray: rgba(154, 154, 154, 1);
$gray-dark: rgba(138, 145, 156, 1);
$gray-dark-soft: rgba(179, 179, 179, 1);
$gray-light: rgb(240, 240, 240);
$gray-c: rgb(204, 204, 204);
$gray-soft: rgba(179, 179, 179, 1);
$black: rgba(18, 18, 18, 1);
$white: rgba(255, 255, 255, 1);
$dark-button: rgba(44, 44, 44, 1);

.chat-loader {
  width: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  height: 12px;
  display: block;
  flex: 0 0 auto;
  align-items: center;
  align-self: center;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 $black, -20px 0 $gray;
    background: #000
  }
  33% {
    box-shadow: 20px 0 $black, -20px 0 $gray;
    background: $gray
  }
  66% {
    box-shadow: 20px 0 $gray, -20px 0 $black;
    background: #0002
  }
  100% {
    box-shadow: 20px 0 $gray, -20px 0 $black;
    background: $black
  }
}


.chat {
  width: 100%;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0;
  border: none;
  position: sticky;
  background-color: $white;
  top: 0;
  border-radius: 20px;
  padding-top: 10px;
  height: calc(81vh - 52px);

  .body {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 60vh;
    margin: 0 5px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $gray-light;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $gray-c;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $gray-c;
    }

    .file-area {
      max-width: 350px;
      font-size: 12px;
      display: flex;
      border: 1px solid;
      border-radius: 15px;
      padding: 10px;

      &.self {
        margin-left: auto;
      }

      a {
        color: $black;
        text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 6px;
        margin: 0;
      }

      i {
        font-size: 30px;
      }
    }

    .message-area {
      display: flex;
      gap: 10px;

      .user-pic {
        width: 48px;
        height: 48px;
        border-radius: 48px;
        background: $gray-dark;
        margin-top: auto;
        font-size: 22px;
        font-weight: bold;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        &.staff {
          background: $black;
          color: transparent;

          span {
            background: url('@/assets/images/orion_small.svg') no-repeat center center;
            background-size: contain;
            width: 30px;
            height: 30px;
          }

        }
      }

      &.self {
        flex-direction: row-reverse;

        .user-name, .user-email {
          display: none;
        }

        .message {
          margin-left: auto;
          border-radius: 15px 14px 0 15px;
          background: $gray-light;

        }
      }


      .message {
        width: 80%;
        border: none;
        border-radius: 15px 15px 15px 0;
        padding: 15px;
        background: $blue;
        position: relative;
        max-width: 350px;
        display: flex;
        flex-direction: column;


        .user-name {
          font-size: 14px;
          font-weight: 500;
        }

        .user-email {
          font-size: 10px;
          color: $gray;
        }

        .files {
          cursor: pointer;
          max-height: max-content;
        }

        .text {
          font-size: 13px;
          white-space: pre-line;

          blockquote {
            font-size: 12px;
            font-style: italic;
            padding: 0 3px;
            margin: 5px;
          }

          blockquote:before {
            color: $gray;
            content: open-quote;
            font-size: 22px;
          }
        }

        .date-time {
          font-size: 12px;
          font-weight: 400;
          color: $gray-soft;
          margin-left: auto;
        }


      }
    }
  }

  .files {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 225px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $gray-light;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $gray-c;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $gray-c;
    }

    .file {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ico {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        background: $gray-soft;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 22px;
        flex: 0 0 auto;
      }

      .file-name {
        display: flex;
        gap: 20px;
        align-items: center;

        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;

          span {
            font-size: 14px;
            font-weight: 400;
            color: $dark-button;

            &.size {
              color: $gray-dark-soft;
            }
          }
        }
      }

      .delete {
        color: $gray-soft;
        font-size: 15px;
      }
    }
  }

  .footer {
    padding: 20px;
    background: transparent;

    form {
      display: flex;
      flex-direction: column;
      position: relative;
      border: 1px solid #B3B3B3;
      width: 100%;
      border-radius: 70px;
      padding: 15px 30px;

      .attach-area {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
        }

      }

      .file-wrapper {
        box-shadow: 0 0 10.3px 0 rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(11px);
        position: absolute;
        top: -340px;
        height: 305px;
        width: 100%;
        left: 0;
        background: $white;
        border-radius: 10px;
        border: 1px solid rgba(234, 234, 234, 1);
        padding: 25px;

        .close {
          position: absolute;
          right: 5px;
          top: 5px;
          color: $gray;
          font-size: 20px;
        }

        &:after {
          content: "";
          position: absolute;
          right: 22px;
          bottom: -41px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 27px 17px 17px;
          border-color: $white transparent transparent transparent;
          -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .5));
          filter: drop-shadow(0 10px 10px rgba(0, 0, 0, .25));
        }

        .file-area {
          border: 2px dotted $gray-c;
          border-radius: 10px;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
          justify-content: center;
          text-align: center;

          &.active {
            border-color: $blue;
            background: $gray-light;
          }

          .ico {
            background: url('@/assets/images/file.svg') no-repeat center center;
            width: 64px;
            height: 64px;
            display: block;
          }

          input {
            display: none;
          }

          .title {
            font-size: 32px;
            font-weight: 400;
            line-height: 42px;
          }

          .sub-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: $gray-dark-soft;
          }
        }
      }

      .buttons {
        display: none;
        padding-top: 20px;
        justify-content: space-between;

        .group {
          margin-left: auto;
          display: flex;
          gap: 20px;
        }
      }

      &.has-content {
        .open-file {
          display: none;
        }

        .buttons {
          display: flex;
        }
      }


      textarea {
        width: 100%;
        min-width: auto;
        outline: 0;
        resize: none;
        border: none;
        border-radius: 0;
        height: 20px;

        &:focus {
          border-bottom: 1px solid $gray-dark-soft;
        }

        &::placeholder {
          color: #B3B3B3;
          font-size: 16px;
          line-height: 21px;
          //transform: translateY(10px);
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }


      .open-file {
        position: absolute;
        right: 15px;
        font-size: 25px;
        color: #ccc;
        top: 15px;
        transform: rotate(45deg);

        i {
          color: $gray;
        }
      }

      input {
        display: none;
      }
    }

    .attach-area {
      display: flex;
      justify-content: start;
      font-size: 12px;
      padding: 5px;
    }

  }


}
</style>