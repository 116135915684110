<template>

  <div>
    <Header></Header>
    <notifications/>

  <template v-if="$store.getters.user || $route.meta.notAuth">
    <div class="wrapper">
      <MainMenu v-if="$store.getters.isLoggedIn && $store.getters.token"></MainMenu>

        <section :class="$store.getters.isLoggedIn?' ':'bg-dark'">
          <router-view/>
        </section>
      </div>



      <span class="loader" v-if="$store.getters.load">
<!--                   <span class="loader">-->
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                        </span>
  </template>

   <template v-else-if="$route.name === 'PageNotFound'">
      <div class="wrapper">
        <section>
          <router-view/>
        </section>
      </div>
    </template>
  <template v-else>
      <div class="load-logo">
        <svg width="790" height="177" viewBox="0 0 790 177" fill="none" xmlns="http://www.w3.org/2000/svg">
           <defs>
            <linearGradient id="left-to-right">
              <stop offset="0" stop-color="#000000">
                <animate dur="0.5s" attributeName="offset" fill="freeze" from="0" to="1"/>
              </stop>
              <stop offset="0" stop-color="#fff">
                <animate dur="0.5s" attributeName="offset" fill="freeze" from="0" to="1"/>
              </stop>

            </linearGradient>
          </defs>
<path d="M209.163 176.65V0H289.472C309.778 0 325.938 5.68428 338.164 17.0528C350.389 28.4214 356.432 42.4864 356.432 59.3206C356.432 72.4381 352.849 83.661 345.752 92.9161C338.585 102.171 329.17 109.094 317.507 113.613L356.432 176.723H315.048L279.776 118.714H245.699V176.723H209.233L209.163 176.65ZM245.629 83.2966H288.208C298.255 83.2966 305.843 81.1832 310.972 76.8836C316.102 72.5839 318.631 66.7539 318.631 59.3206C318.631 51.8873 316.102 44.2384 310.972 39.9388C305.843 35.6392 298.255 32.7385 288.208 32.7385H245.629V83.2966Z" fill="url(#left-to-right)"/>
<path d="M378.312 176.424V0H414.688V176.424H378.312Z" fill="url(#left-to-right)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M185.885 0H94.5779V0.0139938C93.9692 0.00468849 93.3581 3.58463e-05 92.7447 3.58463e-05C66.445 3.58463e-05 44.3894 8.55337 26.6476 25.66C8.90572 42.7667 0 63.6672 0 88.3615C0 113.056 8.90572 133.956 26.6476 151.063H26.578C44.3199 168.17 66.3754 176.723 92.7447 176.723C119.114 176.723 141.17 168.17 158.911 151.063C176.653 133.956 185.559 113.056 185.559 88.3615C185.559 66.975 178.879 48.434 165.565 32.7385H185.885V0ZM38.5451 88.3615C38.5451 72.2895 43.6241 59.1146 53.8518 48.8368C64.0098 38.559 77.0205 33.4546 92.7447 33.4546C108.469 33.4546 121.41 38.559 131.638 48.8368C141.865 59.1146 146.944 72.2895 146.944 88.3615C146.944 104.433 141.865 117.608 131.638 127.886C121.48 138.164 108.469 143.268 92.7447 143.268C77.0205 143.268 64.0794 138.164 53.8518 127.886C43.6241 117.608 38.5451 104.433 38.5451 88.3615Z" fill="url(#left-to-right)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M437.966 176.723H529.273V176.709C529.881 176.718 530.493 176.723 531.106 176.723C557.406 176.723 579.461 168.17 597.203 151.063C614.945 133.956 623.851 113.056 623.851 88.3614C623.851 63.6671 614.945 42.7667 597.203 25.66H597.273C579.531 8.55336 557.475 1.52588e-05 531.106 1.52588e-05C504.737 1.52588e-05 482.681 8.55336 464.939 25.66C447.197 42.7667 438.292 63.6671 438.292 88.3614C438.292 109.748 444.971 128.289 458.285 143.984H437.966V176.723ZM585.306 88.3614C585.306 104.433 580.226 117.608 569.999 127.886C559.841 138.164 546.83 143.268 531.106 143.268C515.382 143.268 502.441 138.164 492.213 127.886C481.985 117.608 476.906 104.433 476.906 88.3614C476.906 72.2895 481.985 59.1146 492.213 48.8368C502.371 38.559 515.382 33.4546 531.106 33.4546C546.83 33.4546 559.771 38.559 569.999 48.8368C580.226 59.1146 585.306 72.2895 585.306 88.3614Z" fill="url(#left-to-right)"/>
<path d="M755.402 0H790V176.723H760.002L680.407 64.4947V176.723H645.676V0H675.808L755.402 112.374V0Z" fill="url(#left-to-right)"/>
</svg>

      </div>
    </template>
  </div>
</template>

<script>
import Header from '@/components/Header'
import MainMenu from '@/components/includes/Menu'
import {useFavicon} from '@vueuse/core'
import store from "@/store";
import axios from "axios"
import 'bootstrap-icons/font/bootstrap-icons.css'
import(`@/assets/scss/main.scss`)
import("bootstrap/js/index.esm");

const icon = useFavicon()

icon.value = `/img/${store.getters.company.favicon}`

export default {
  name: 'App',
  components: {
    Header, MainMenu,
  },
  mounted() {
    let url = new URL(location)
    const hash = url.searchParams.get('hash');
    if (hash) {
      const parse_hash = JSON.parse(atob(hash))
      const base = {
        baseURL: this.$store.getters.api_path,
        headers: {
          Authorization: `JWT ${parse_hash.jwt}`,
          "Content-Type": "application/json",
        },
        xhrFields: {
          withCredentials: true,
        },
      };
      const axiosInstance = axios.create(base);
      axiosInstance({
        url: "/staff/organization_login/",
        method: "post",
        data: parse_hash,
      }).then((response) => {
        this.$store.commit("updateToken", response.data.token);
        setTimeout(() => {
          this.$store.dispatch('get_user');
          location = '/';
        }, 300)

      }).catch(err => {
        this.$notify({
          group: 'alert',
          type: 'error ',
          text: err.response.data.error
        });
        location = '/';
      });
    }
  },
  created: function () {
    let $this = this;
    this.$store.dispatch('refresh_token');

    setInterval(function () {
      $this.$store.dispatch('refresh_token');
    }, 1000 * 60 * 5);
    this.$store.dispatch('projects');
  }
}
</script>

