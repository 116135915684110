<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col">
          <CountryChoice
              v-bind:active_country.sync="active_country"
              v-bind:project_id="$route.params.id"
              v-bind:countries="country_options"
              v-bind:changeCountry="changeCountry"></CountryChoice>
        </div>
        <div class="col-md-3">
          <select id="state" v-model="state" class="form-select" required="">
            <option v-for="st in states" :value="st">ТОП {{ st }}</option>
          </select>
        </div>

      </div>
    </div>
    <div style="overflow: hidden" class="">
      <div v-if="chart_data && chart_data.labels" :class="`card-body card ${by_line?'stat':''} p-0 m-2`">
        <div id="legend-container" class="legend"></div>
        <div :class="`${by_line?'chart-by-line':''}`"
             :style="`${by_line?`width: ${(chart_data.labels.length-1)*161+380}px;`:''} height: 80vh;`">

          <Line
              v-if="chart_data"
              :options="chartOptions"
              :data="chart_data"
              :plugins="[htmlLegendPlugin]"
          />
        </div>


        <template v-if="stat && this.snapshot.snapshot.country_data.find(x=> x.code == this.active_country.code)">
          <div :class="`${by_line?'':'table-responsive'}`"
               :style="by_line?`padding-right: ${(chart_data.labels.length-Object.keys(this.snapshot.snapshot.country_data.find(x=> x.code == this.active_country.code).data.chart_data[`top_${state}`].dates).length)*160}px`:''">
            <table class="table text-center">
              <thead>
              <tr>
                <th class="fixed" rowspan="3"></th>
                <td v-for="date in Object.keys(this.snapshot.snapshot.country_data.find(x=> x.code == this.active_country.code).data.chart_data[`top_${state}`].dates)"
                    colspan="2" width="160" class="table-head">
                  <div class="h5 head">{{ date }}</div>
                </td>
              </tr>
              <tr>
                <template
                    v-for="count in Object.keys(this.snapshot.snapshot.country_data.find(x=> x.code == this.active_country.code).data.chart_data[`top_${state}`].dates)">
                  <th><i class="yandex"></i>
                    <div class="d-none">{{ count }}</div>
                  </th>
                  <th><i class="google"></i></th>
                </template>
              </tr>
              <tr v-if="snapshot">
                <th v-for="date in Object.keys(this.snapshot.snapshot.country_data.find(x=> x.code == this.active_country.code).data.chart_data[`top_${state}`].dates)"
                    colspan="2" class="text-center">
                  <b>{{
                      snapshot.snapshot.country_data.find(x => x.code == active_country.code).data.chart_data[`top_${state}`].dates[date]['real']
                    }}</b>
                </th>
              </tr>
              </thead>
              <tbody>

              <tr class="table-light">
                <th :class="`fixed text-start${by_line?' by_line':''}`">
                  <b>{{ snapshot.snapshot.name }}</b>
                </th>
                <template
                    v-for="date in Object.keys(this.snapshot.snapshot.country_data.find(x=> x.code == this.active_country.code).data.chart_data[`top_${state}`].dates)">
                  <td>

                    {{
                      snapshot.snapshot.country_data.find(x => x.code == active_country.code).data.chart_data[`top_${state}`].dates[date]['y']
                    }}
                  </td>
                  <td>

                    {{
                      snapshot.snapshot.country_data.find(x => x.code == active_country.code).data.chart_data[`top_${state}`].dates[date]['g']
                    }}

                  </td>
                </template>
              </tr>
              <tr v-for="key in  Object.keys(this.snapshot.snapshot.country_data.find(x=> x.code == this.active_country.code).data.chart_data[`top_${state}`].keywords)">
                <th :class="`text-start fixed${by_line?' by_line':''}`">
                  {{ key }}
                </th>

                <template
                    v-for="date in snapshot.snapshot.country_data.find(x=> x.code == active_country.code).data.chart_data[`top_${state}`].keywords[key]">
                  <td>
                    {{ date.y }}
                  </td>
                  <td>
                    {{ date.g }}
                  </td>
                </template>
              </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {ProjectApi} from "@/api/project";
import moment from "moment";
import CountryChoice from "@/components/CountryChoice"


import {Line} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)
import htmlLegendPlugin from "@/components/legendPlugin";

ChartJS.defaults.plugins.legend.display = false;


export default {
  name: "ForecastDashboard",
  components: {
    CountryChoice,
    Line,
  },

  props: ["id", "stat"],
  data() {
    return {
      htmlLegendPlugin: htmlLegendPlugin,
      current_date: moment(),
      active_date: moment(),
      old_state: null,
      by_line: false,
      state: 20,
      states: [10, 20, 50, 100],
      keyword: null,
      value: [],
      value_max: 100,
      tone: "negative",
      active_country: {},
      show_legend: true,
      by_date: false,
      active_project: {},
      forecasts: [],
      keywords: [],
      datacollection: null,
      snapshot: {},

      country_options: [],

      data_set_options: {
        'plan': {
          'type': 'line', 'label': 'План', 'fill': 'false',
          'borderColor': 'rgb(104, 234, 172)', 'lineTension': 0
        },
        'real': {
          'type': 'line', 'label': 'Сумма нецелевых ресурсов', 'fill': 'false',
          'borderColor': 'rgb(0, 0, 0)', 'lineTension': 0
        },
        'g': {
          'type': 'line', 'label': 'Сумма нецелевых ресурсов google', 'fill': 'false',
          'borderColor': 'rgb(102, 112, 192)', 'lineTension': 0
        },
        'y': {
          'type': 'line', 'label': 'Сумма нецелевых ресурсов yandex', 'fill': 'false',
          'borderColor': 'rgb(255, 0, 0)', 'lineTension': 0
        }
      },

      project: {}
    };
  },
  computed: {

    chartOptions() {
      let $this = this, d = moment().day() - (moment().day() ? 1 : -6)
      let week_start = moment().subtract(d + (d <= this.active_project.report_weekday ? 7 : 0) - this.active_project.report_weekday, "days");

      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        layout: {
          padding: 20
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              backdropColor: function (x) {
                return x.tick.label === $this.$moment(new Date).format('YYYY-MM-DD') ? '#ddd' : '#fff'
              },
              showLabelBackdrop: true,
              font: function (x) {
                let label_date = moment(x.tick.label, 'YYYY-MM-DD')
                return (moment(label_date).isSameOrAfter(week_start, 'day') && label_date <= moment()) ? {
                  size: 14,
                  weight: 'bold'
                } : {size: 12}
              },
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
            },
          },
          y: {
            afterFit: function (scaleInstance) {
              if ($this.by_line) scaleInstance.width = 380;
            },
            grid: {
              display: false
            },
            ticks: {
              display: false,
            },
            maxTicksLimit: (Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => typeof x === 'number')) || 0) + 10,
            suggestedMax: (Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => typeof x === 'number')) || 0) + 10,
            max: (Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => typeof x === 'number')) || 0) + 10,
          },
        },
      }
    },

    chart_data() {
      if (this.datacollection && this.datacollection.labels) {
        let new_data = [];
        for (let i in this.datacollection.datasets) {
          let d = this.datacollection.datasets[i];
          let n_d = {
            borderColor: d.borderColor,
            data: d.data.slice(this.value[0], this.value[1]),
            fill: d.fill,
            label: d.label,
            lineTension: d.lineTension,
            type: d.type,
          };
          new_data.push(n_d);
        }
        return {
          labels: this.datacollection.labels.slice(
              this.value[0],
              this.value[1]
          ),
          datasets: new_data,
        };
      } else return this.datacollection;
    }

  },

  watch: {
    active_country: function () {
      this.getKeywords();
    },

    keyword: function () {
      this.fillData();
    },

    by_date: function () {
      this.fillData();
    },
  },

  mounted: function () {
    this.get_snapshot(moment().format('DD/MM/YYYY'))

  },

  methods: {

    formated_date(date) {
      let formated_date = moment(date, 'DD/MM/YYYY');
      return `${formated_date.year()}-${formated_date.month() + 1}-${formated_date.date()}`;
    },

    get_snapshot(date) {
      ProjectApi.get_snapshot(this.$route.params.id, this.formated_date(date)).then(response => {
        console.log('get_snapshot', response);
        this.snapshot = response
        this.country_options = response.project_tr_countries
        this.active_country = this.country_options[0]


      })
    },

    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
      }
    },
    formatten(value) {
      if (this.datacollection) {
        let val = this.datacollection.labels[value];
        if (!val) val = this.datacollection.labels[value - 1]
        return `${val}`;
      }
      return '';
    },

    getKeywords() {
      if (this.snapshot.snapshot.country_data.find(x => x.code == this.active_country.code)) {
        this.keywords = Object.keys(this.snapshot.snapshot.country_data.find(x => x.code == this.active_country.code).data.chart_data[`top_${this.state}`].keywords);
        this.fillData();
      } else {
        this.keywords = []
        this.datacollection = {labels: [], datasets: []};
      }
    },

    //   forecastStat() {
    //       let $this = this;
    //       if ($this.stat) {
    //           let $this = this;
    //           let filters = {
    //               state: $this.state,
    //               keyword: $this.keyword ? $this.keyword : "",
    //               country: $this.active_country ? $this.active_country.code : "RU",
    //           };
    //           let f_string = new URLSearchParams(filters).toString();
    //           ProjectApi.get_forecast_stat($this.id, f_string).then(
    //               (response) => {
    //                   $this.project = response.data;

    //                   console.log('forecastStat response.data', response.data);
    //               }
    //           );
    //       }

    //   },
    //   fillData() {
    //       let $this = this;
    //       ProjectApi.get_chart_data($this.id, {
    //           state: $this.state,
    //           keyword: $this.keyword,
    //           by_date: $this.by_date,
    //           country: $this.active_country.code,
    //       }).then((response) => {
    //           let response_data_length = Object.keys(response.data).length
    //           $this.value = [0, response_data_length];
    //           $this.value_max = response_data_length;

    //           let new_data = [];
    //           let data_set_options_keys = Object.keys(this.data_set_options);

    //           for (let i = 0; i < data_set_options_keys.length; ++i) {
    //               let data_set_key = data_set_options_keys[i]

    //               let getted_data = []
    //               for (let j in response.data) {
    //                   getted_data.push(response.data[j][data_set_key])
    //               }
    //               console.log('getted_data', getted_data);
    //               let n_d = {
    //                   borderColor: this.data_set_options[data_set_key].borderColor,
    //                   data: getted_data,
    //                   fill: this.data_set_options[data_set_key].fill,
    //                   label: this.data_set_options[data_set_key].label,
    //                   lineTension: this.data_set_options[data_set_key].lineTension,
    //                   type: this.data_set_options[data_set_key].type,
    //               };
    //               new_data.push(n_d);
    //           }

    //           $this.datacollection = {
    //               labels: Object.keys(response.data),
    //               datasets: new_data,
    //           };
    //           $this.forecastStat()
    //       });

    //   },
    fillData() {
      let $this = this;

      let response_data_length = Object.keys(this.snapshot.snapshot.country_data.find(x => x.code == this.active_country.code).data.chart_data[`top_${this.state}`].dates).length
      $this.value = [0, response_data_length];
      $this.value_max = response_data_length;


      let new_data = [];
      let data_set_options_keys = Object.keys(this.data_set_options);

      for (let i = 0; i < data_set_options_keys.length; ++i) {
        let data_set_key = data_set_options_keys[i]
        let getted_data = []

        for (let j in this.snapshot.snapshot.country_data.find(x => x.code == this.active_country.code).data.chart_data[`top_${this.state}`].dates) {
          getted_data.push(this.snapshot.snapshot.country_data.find(x => x.code == this.active_country.code).data.chart_data[`top_${this.state}`].dates[j][data_set_key])
        }
        let n_d = {
          borderColor: this.data_set_options[data_set_key].borderColor,
          data: getted_data,
          fill: this.data_set_options[data_set_key].fill,
          label: this.data_set_options[data_set_key].label,
          lineTension: this.data_set_options[data_set_key].lineTension,
          type: this.data_set_options[data_set_key].type,
        };
        new_data.push(n_d);
      }

      $this.datacollection = {
        labels: Object.keys(this.snapshot.snapshot.country_data.find(x => x.code == this.active_country.code).data.chart_data[`top_${this.state}`].dates),
        datasets: new_data,
      };

    },
  },
};
</script>

<style>

.date {
  height: 100px;
  margin: 20px 0;
}

.link {
  cursor: pointer;
}

td {
  max-width: 400px;
}

td.table-head {
  max-width: 160px;
  min-width: 160px;
}
</style>

<style scoped>
.chart-by-line {
  height: 400px;
}

.card.stat {
  white-space: nowrap;
  overflow-x: scroll;
  padding-bottom: 50px;
}


.fixed {
  position: sticky;
  top: 0;
  left: 0;
  white-space: nowrap;
}

.fixed.by_line {
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  overflow: hidden;
}

.fixed:not(.gray-bg) {
  background: #fff;
}

/* .fixed + div {
margin-left: 350px!important;
} */

.head {
  display: block;
  width: 160px;
  margin: 0;
  padding: 0;
}

.uniq-p {
  padding-top: 40px;
}
</style>
